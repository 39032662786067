<template>
    <div>
        <md-button @click.native="$router.push({name: 'family-stat', params: {page: $route.params.page}})">Back</md-button>
        <md-table v-model="members" md-card>
            <md-table-toolbar>
                <h1 class="md-title">Family info</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="Avatar" class="avatar-item"><img :src="item.avatar_url" :alt="item.name"></md-table-cell>
                <md-table-cell md-label="Type">{{ item.family_member_type }}</md-table-cell>
                <md-table-cell md-label="Gender">{{ item.gender }}</md-table-cell>
                <md-table-cell md-label="Birth Date">{{ item.birth_date || '-' }}</md-table-cell>
                <md-table-cell md-label="Active tasks">{{ item.active_task_count }}</md-table-cell>
                <md-table-cell md-label="Completed tasks">{{ item.completed_task_count }}</md-table-cell>
                <md-table-cell md-label="Active rewards">{{ item.active_reward_count }}</md-table-cell>
                <md-table-cell md-label="Completed rewards">{{ item.completed_reward_count }}</md-table-cell>
                <md-table-cell md-label="Registration date">{{ item.created_at }}</md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
    export default {
        name: "FamilyItem",
        created() {
            this.$dispatch('member/loadAdminList', this.$route.params.id)
        },
        computed: {
            members() {
                return this.$get("member/list");
            }
        }
    }
</script>

<style lang="scss" scoped>
    .avatar-item{
        width: 100px;
    }
</style>
